.perspective {
  perspective: 1000px;
}

.backface-hidden {
  backface-visibility: hidden;
}

@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}

.animate-ripple {
  animation: ripple 3s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.animation-delay-300 {
  animation-delay: 300ms;
}

.animation-delay-600 {
  animation-delay: 600ms;
}

body::-webkit-scrollbar {
  width: 0;
}
